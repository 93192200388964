* {
  color: white;
}

input {margin-bottom:18px;}

button {
  color: black
}

.center-coding-languages {
  padding-left:  0px;  
  padding-right: 0px;
  text-align: center;
}

.center-form {
  padding-left:  0px;  
  padding-right: 60px;
  text-align: center;
}

.center-text {
  display: inline-block; 
  text-align: left;
}

.form-row {
  display: flex;
  flex-direction: row;
}

.form-submit {
  display: inline-block;
  padding: 12px 24px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-submit:hover {
  background-color: #0056b3;
}
