.text{
  color: black
}

.center-gpt {
  padding-left:  0px;  
  padding-right: 0px;
  text-align: center;
}

p.answer {
  padding-left: 50px;
  padding-right: 50px;
}

.warning {
  margin: 10px 0px
}