.{
  color: white
}

.results {
  padding-left: 220px
}

h1.results {
  color: #b97fb5
}

.text {
  padding: 10px;
  font-size: 14px;
  width: 150px; /* Adjust the width as needed */
}